import type { GetServerSideProps } from 'next'
import type { CustomAppProps, CustomNextPage } from 'types/nextCustomType'
import type { IHomeResponse } from 'types/services/home-response'

import { getSession } from 'next-auth/react'
import { getServerSideTranslations } from 'utils/next-i18next'

const HomePage: CustomNextPage<CustomAppProps<{pageData: IHomeResponse}>> = ({ pageData }) => {
  return (
    <div/>
  )
}

export const getServerSideProps: GetServerSideProps = async ({
  req,
  locale,
  ...ctx
}) => {
  const session = await getSession({ req })

  if (session) {
    return {
      redirect: {
        destination: process.env.WEBAPP_URL as string,
        permanent: false
      }
    }
  }

  return {
    props: {
      ...(await getServerSideTranslations(locale as string, [
        'common',
        'navigation',
        'profileSideNav',
        'home'
      ]))
    }
  }
}

HomePage.auth = true

export default HomePage
